import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';

const initialState: {
  AWS_ACCESS_KEY_ID: string;
  AWS_SECRET_ACCESS_KEY: string;
  GOOGLE_API_KEY: string;
} = {
  AWS_ACCESS_KEY_ID: '',
  AWS_SECRET_ACCESS_KEY: '',
  GOOGLE_API_KEY: ''
};

export const credentialsStateSlice = createSlice({
  name: 'credentialsStateSlice',
  initialState,
  reducers: {
    setCredential: (state, action) => {
      return {
        AWS_ACCESS_KEY_ID: action.payload.AWS_ACCESS_KEY_ID,
        AWS_SECRET_ACCESS_KEY: action.payload.AWS_SECRET_ACCESS_KEY,
        GOOGLE_API_KEY: action.payload.GOOGLE_API_KEY
      };
    },
    resetCredential: () => {
      return initialState
    }
  }
});

export const { setCredential, resetCredential } = credentialsStateSlice.actions;

export const getCredential = (state: RootState) => state.credentialsStateSlice;
