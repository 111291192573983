import { zipCodeApi as api } from './zipCodeApi';
export const addTagTypes = ['account_details', 'region'] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: build => ({
      accountDetailsDownloadCentreCreate: build.mutation<
        AccountDetailsDownloadCentreCreateApiResponse,
        AccountDetailsDownloadCentreCreateApiArg
      >({
        query: queryArg => ({
          url: `/account_details/download_centre/`,
          method: 'POST',
          body: queryArg.downloadCentre
        }),
        invalidatesTags: ['account_details']
      }),
      regionDistrictLite: build.query<
        RegionDistrictLiteApiResponse,
        RegionDistrictLiteApiArg
      >({
        query: queryArg => ({
          url: `/region/district/lite/`,
          params: {
            country__uuid: queryArg.countryUuid,
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset
          }
        }),
        providesTags: ['region']
      }),
      regionSuperregionList: build.query<
        RegionSuperregionListApiResponse,
        RegionSuperregionListApiArg
      >({
        query: queryArg => ({
          url: `/region/superregion/`,
          params: {
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset
          }
        }),
        providesTags: ['region']
      }),
      regionSuperregionLite: build.query<
        RegionSuperregionLiteApiResponse,
        RegionSuperregionLiteApiArg
      >({
        query: queryArg => ({
          url: `/region/superregion/lite/`,
          params: {
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset
          }
        }),
        providesTags: ['region']
      }),
      regionZipcodesList: build.query<
        RegionZipcodesListApiResponse,
        RegionZipcodesListApiArg
      >({
        query: queryArg => ({
          url: `/region/zipcodes/`,
          params: {
            country__name: queryArg.countryName,
            country__uuid: queryArg.countryUuid,
            state__name: queryArg.stateName,
            state__uuid: queryArg.stateUuid,
            zip: queryArg.zip,
            district__name: queryArg.districtName,
            district__uuid: queryArg.districtUuid,
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset
          }
        }),
        providesTags: ['region']
      }),
      regionZipcodesListTemporary: build.query<
        RegionZipcodesListTemporaryApiResponse,
        RegionZipcodesListTemporaryApiArg
      >({
        query: queryArg => ({
          url: `/region/zipcodes/list-uploaded/`,
          params: {
            country__name: queryArg.countryName,
            country__uuid: queryArg.countryUuid,
            state__name: queryArg.stateName,
            state__uuid: queryArg.stateUuid,
            zip: queryArg.zip,
            district__name: queryArg.districtName,
            district__uuid: queryArg.districtUuid,
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset,
            country_uuid: queryArg._countryUuid
          }
        }),
        providesTags: ['region']
      }),
      regionZipcodesLite: build.query<
        RegionZipcodesLiteApiResponse,
        RegionZipcodesLiteApiArg
      >({
        query: queryArg => ({
          url: `/region/zipcodes/lite/`,
          params: {
            country__name: queryArg.countryName,
            country__uuid: queryArg.countryUuid,
            state__name: queryArg.stateName,
            state__uuid: queryArg.stateUuid,
            zip: queryArg.zip,
            district__name: queryArg.districtName,
            district__uuid: queryArg.districtUuid,
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset
          }
        }),
        providesTags: ['region']
      }),
      regionZipcodesUpdateMultipleUpdate: build.mutation<
        RegionZipcodesUpdateMultipleUpdateApiResponse,
        RegionZipcodesUpdateMultipleUpdateApiArg
      >({
        query: queryArg => ({
          url: `/region/zipcodes/update_multiple/`,
          method: 'PUT',
          body: queryArg.body
        }),
        invalidatesTags: ['region']
      }),
      regionZipcodesUploadFile: build.mutation<
        RegionZipcodesUploadFileApiResponse,
        RegionZipcodesUploadFileApiArg
      >({
        query: queryArg => ({
          url: `/region/zipcodes/upload/`,
          method: 'POST',
          body: queryArg.zipCodeFileUpload,
          formData: true
        }),
        invalidatesTags: ['region']
      }),
      regionZipcodesUploadUploadProceed: build.mutation<
        RegionZipcodesUploadUploadProceedApiResponse,
        RegionZipcodesUploadUploadProceedApiArg
      >({
        query: queryArg => ({
          url: `/region/zipcodes/upload/proceed/`,
          method: 'POST',
          body: queryArg.zipCodeUploadProceed
        }),
        invalidatesTags: ['region']
      }),
      regionZonalRegionList: build.query<
        RegionZonalRegionListApiResponse,
        RegionZonalRegionListApiArg
      >({
        query: queryArg => ({
          url: `/region/zonal_region/`,
          params: {
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset
          }
        }),
        providesTags: ['region']
      })
    }),
    overrideExisting: false
  });
export { injectedRtkApi as enhancedApi };
export type AccountDetailsDownloadCentreCreateApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: DownloadCentreRead[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type AccountDetailsDownloadCentreCreateApiArg = {
  downloadCentre: DownloadCentre;
};
export type RegionDistrictLiteApiResponse = /** status 200 Custom Response */ {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results: DistrictListRead[];
  ok?: boolean;
  message?: {
    text?: string | null;
    code?: number;
    args?: object | null;
  };
};
export type RegionDistrictLiteApiArg = {
  /** country__uuid */
  countryUuid?: string;
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type RegionSuperregionListApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: SuperRegionRead[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type RegionSuperregionListApiArg = {
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type RegionSuperregionLiteApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: SuperRegionRead[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type RegionSuperregionLiteApiArg = {
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type RegionZipcodesListApiResponse = /** status 200 Custom Response */ {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results: ZipCodesRead[];
  ok?: boolean;
  message?: {
    text?: string | null;
    code?: number;
    args?: object | null;
  };
};
export type RegionZipcodesListApiArg = {
  /** country__name */
  countryName?: string;
  /** country__uuid */
  countryUuid?: string;
  /** state__name */
  stateName?: string;
  /** state__uuid */
  stateUuid?: string;
  /** zip */
  zip?: string;
  /** district__name */
  districtName?: string;
  /** district__uuid */
  districtUuid?: string;
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type RegionZipcodesListTemporaryApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: ZipCodesRead[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type RegionZipcodesListTemporaryApiArg = {
  /** country__name */
  countryName?: string;
  /** country__uuid */
  countryUuid?: string;
  /** state__name */
  stateName?: string;
  /** state__uuid */
  stateUuid?: string;
  /** zip */
  zip?: string;
  /** district__name */
  districtName?: string;
  /** district__uuid */
  districtUuid?: string;
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
  /** State ID */
  _countryUuid?: number;
};
export type RegionZipcodesLiteApiResponse = /** status 200 Custom Response */ {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results: ZipCodesRead[];
  ok?: boolean;
  message?: {
    text?: string | null;
    code?: number;
    args?: object | null;
  };
};
export type RegionZipcodesLiteApiArg = {
  /** country__name */
  countryName?: string;
  /** country__uuid */
  countryUuid?: string;
  /** state__name */
  stateName?: string;
  /** state__uuid */
  stateUuid?: string;
  /** zip */
  zip?: string;
  /** district__name */
  districtName?: string;
  /** district__uuid */
  districtUuid?: string;
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type RegionZipcodesUpdateMultipleUpdateApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: ZipCodesRead[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type RegionZipcodesUpdateMultipleUpdateApiArg = {
  body: ZipCodes[];
};
export type RegionZipcodesUploadFileApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: ZipCodesRead[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type RegionZipcodesUploadFileApiArg = {
  zipCodeFileUpload: FormData;
};
export type RegionZipcodesUploadUploadProceedApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: ZipCodesRead[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type RegionZipcodesUploadUploadProceedApiArg = {
  zipCodeUploadProceed: ZipCodeUploadProceed;
};
export type RegionZonalRegionListApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: ZonalRegionRead[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type RegionZonalRegionListApiArg = {
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type DownloadCentre = {
  name: string;
  url?: string | null;
  created_by?: string;
  file_type?: string | null;
  file_size?: string;
  model_type?: string | null;
  fields?: string | null;
  filters?: object | null;
};
export type DownloadCentreRead = {
  uuid?: string;
  id?: number;
  name: string;
  url?: string | null;
  created_by?: string;
  created_at?: string;
  file_type?: string | null;
  file_size?: string;
  status?: 'inprogress' | 'downloadable';
  model_type?: string | null;
  fields?: string | null;
  filters?: object | null;
};
export type StateLite = {
  name: string;
  st: string;
};
export type StateLiteRead = {
  uuid?: string;
  name: string;
  st: string;
};
export type Country = {
  name?: string | null;
  co: string;
  ordering: string;
};
export type CountryRead = {
  uuid?: string;
  name?: string | null;
  co: string;
  ordering: string;
};
export type DistrictList = {
  name?: string | null;
  prev_lead?: string | null;
  until?: string | null;
  date_time?: string | null;
  lead_name?: string | null;
  state?: StateLite;
  country?: Country;
};
export type ProfileLiteRegion = {
  profile_pic?: string | null;
  cover_pic?: string | null;
};
export type ProfileLiteRegionRead = {
  uuid?: string;
  profile_pic?: string | null;
  cover_pic?: string | null;
};
export type LocalLeader = {
  first_name?: string;
  last_name?: string;
  profile?: ProfileLiteRegion;
};
export type LocalLeaderRead = {
  uuid?: string;
  first_name?: string;
  last_name?: string;
  profile?: ProfileLiteRegionRead;
};
export type ZipCodesLite = {
  zip: string;
  primary_city?: string | null;
  timezone?: string | null;
};
export type ZipCodesLiteRead = {
  uuid?: string;
  zip: string;
  primary_city?: string | null;
  timezone?: string | null;
};
export type DistrictListRead = {
  uuid?: string;
  name?: string | null;
  lead?: LocalLeaderRead[];
  prev_lead?: string | null;
  until?: string | null;
  date_time?: string | null;
  lead_name?: string | null;
  state?: StateLiteRead;
  country?: CountryRead;
  zipcodes?: ZipCodesLiteRead[];
  timezone?: string[];
};
export type SuperRegion = {
  name?: string | null;
  country_origin?: string | null;
  match_by_zip?: string | null;
  enabled?: string | null;
  real_distance?: string | null;
};
export type SuperRegionRead = {
  uuid?: string;
  name?: string | null;
  zipcodes?: ZipCodesLiteRead[];
  country_origin?: string | null;
  match_by_zip?: string | null;
  enabled?: string | null;
  real_distance?: string | null;
};
export type ZipCodes = {
  zip: string;
  zip_type?: string | null;
  primary_city?: string | null;
  county?: string | null;
  timezone?: string | null;
  area_codes?: string | null;
  latitude?: string | null;
  longitude?: string | null;
  decommissioned?: number | null;
  timezone_offset?: string | null;
  state?: string | null;
  region?: string | null;
  zonal_region?: string | null;
  district?: string | null;
  country?: string | null;
};
export type ZipCodesRead = {
  uuid?: string;
  zip: string;
  zip_type?: string | null;
  primary_city?: string | null;
  county?: string | null;
  timezone?: string | null;
  area_codes?: string | null;
  latitude?: string | null;
  longitude?: string | null;
  decommissioned?: number | null;
  timezone_offset?: string | null;
  state?: string | null;
  region?: string | null;
  zonal_region?: string | null;
  district?: string | null;
  country?: string | null;
  district_name?: string;
  state_name?: string;
  region_name?: string;
  country_name?: string;
  zonal_region_name?: string;
};
export type ZipCodeFileUpload = {
  country_uuid: string;
};
export type ZipCodeFileUploadRead = {
  file?: string;
  country_uuid: string;
};
export type ZipCodeUploadProceed = {
  country_uuid: string;
};
export type ZonalRegion = {
  name?: string | null;
  zipcodes_set: ZipCodesLite[];
  director: number[];
};
export type ZonalRegionRead = {
  uuid?: string;
  name?: string | null;
  country?: string;
  zipcodes_set: ZipCodesLiteRead[];
  director: number[];
};
export const {
  useAccountDetailsDownloadCentreCreateMutation,
  useRegionDistrictLiteQuery,
  useRegionSuperregionListQuery,
  useRegionSuperregionLiteQuery,
  useRegionZipcodesListQuery,
  useRegionZipcodesListTemporaryQuery,
  useRegionZipcodesLiteQuery,
  useRegionZipcodesUpdateMultipleUpdateMutation,
  useRegionZipcodesUploadFileMutation,
  useRegionZipcodesUploadUploadProceedMutation,
  useRegionZonalRegionListQuery
} = injectedRtkApi;
