import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LocalAreaCardProps } from '@business/workflows/ComponentViews/AreaComponent/LocalAreaCard';
import type { RootState } from './store';

const initialState: LocalAreaCardProps | null = null;

export const countryStateSlice = createSlice({
  name: 'countrySlice',
  initialState,
  reducers: {
    setCountryData: (state, action: PayloadAction<LocalAreaCardProps>) => {
      return action.payload;
    },
    resetCountryData: () => {
      return initialState;
    }
  }
});

export const { setCountryData, resetCountryData } = countryStateSlice.actions;
export const getCountryData = (state: RootState) => state.countrySlice;
