import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';

type resendTimer = {
  isDisabled: boolean;
  timeLeft: number;
};

const initialState: resendTimer = {
  isDisabled: false,
  timeLeft: 0
};

export const resendTimerStateSlice = createSlice({
  name: 'resendTimerStateSlice',
  initialState,
  reducers: {
    setIsDisabled(state, action) {
      state.isDisabled = action.payload;
    },
    setTimeLeft(state, action) {
      state.timeLeft = action.payload;
    },
    resetTimeLeft() {
      return initialState;
    }
  }
});

export const { setIsDisabled, setTimeLeft, resetTimeLeft } = resendTimerStateSlice.actions;
export const getisDisabled = (state: RootState) =>
  state.resendTimerStateSlice?.isDisabled;
export const getTimeLeft = (state: RootState) =>
  state.resendTimerStateSlice?.timeLeft;
