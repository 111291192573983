import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';

export type VolunteerProps = {
  id: string;
  details: any;
  editVolunteer: boolean;
  pendingList?: boolean;
};

const initialState: VolunteerProps = {
  id: '',
  details: null,
  editVolunteer: false,
  pendingList: false
};

export const volunteerStateSlice = createSlice({
  name: 'volunteerStateSlice',
  initialState,
  reducers: {
    setVolunteerId: (state, action) => {
      state.id = action.payload.id;
    },
    resetVolunteer: () => {
      return initialState;
    },
    setEditVolunteer: (state, action) => {
      state.editVolunteer = action.payload.editVolunteer;
    },
    setPendingList: (state, action) => {
      state.pendingList = action.payload.pendingList;
    },
    setVolunteerDetails: (state, action) => {
      state.details = action.payload.details;
    },
  }
});

export const {
  setVolunteerId,
  resetVolunteer,
  setEditVolunteer,
  setPendingList,
  setVolunteerDetails
} = volunteerStateSlice.actions;
export const getVolunteerId = (state: RootState) => state.volunteerStateSlice;
export const getEditVolunteer = (state: RootState) =>
  state.volunteerStateSlice.editVolunteer;
export const getIsPendingList = (state: RootState) =>
  state.volunteerStateSlice.pendingList;
export const getVolunteerDetails = (state: RootState) =>
  state.volunteerStateSlice.details;
