import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SVGAssets } from '@business/assets/vectors';
import { routePaths } from '@utils/constants/routePaths';
import {
  useCommonCredentialsListQuery,
  useUserUserinfoListQuery
} from '@ports/internal-services/user/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { setUserData } from '@ports/common/userStateSlice';
import { setCredential } from '@ports/common/credentialStateSlice';
import { decode } from '@utils/helpers/lib/decode';
import { Avatar } from '../Avatar';
import { getProfileUpdate } from '@ports/common/authStateSlice';
import {
  getPermission,
  setPermission,
  setResourceType,
  setTopMenuClicked
} from '@ports/common/sideBarStateSlice';
import { useNavigate } from 'react-router-dom';

export const Header: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activePermission = useSelector(getPermission);
  const profileUpdate = useSelector(getProfileUpdate);
  const dropdownRef = useRef(null);
  function toggleMenu() {
    const element = document.getElementById('mainNav');
    element.classList.toggle('expand');
  }
  const { data: userInfoData, refetch: refetchUser } = useUserUserinfoListQuery(
    {}
  );
  const { data: credentials } = useCommonCredentialsListQuery();

  useEffect(() => {
    refetchUser();
  }, [profileUpdate]);

  useEffect(() => {
    if (credentials?.ok) {
      const decodedData = decode(credentials?.results);
      dispatch(
        setCredential({
          AWS_ACCESS_KEY_ID: decodedData?.AWS_ACCESS_KEY_ID,
          AWS_SECRET_ACCESS_KEY: decodedData?.AWS_SECRET_ACCESS_KEY,
          GOOGLE_API_KEY: decodedData?.GOOGLE_API_KEY
        })
      );
    }
  }, [credentials]);
  useEffect(() => {
    if (userInfoData?.ok) {
      dispatch(setUserData(userInfoData));
    }
  }, [userInfoData]);
  const usePermissions = useMemo(() => {
    return (
      userInfoData?.results?.permissions?.map(
        permission => permission?.codename
      ) || []
    );
  }, [userInfoData]);
  const menuItems = [
    {
      href: routePaths.Dashboard,
      icon: <SVGAssets.HomeMenu className="w-6 h-7 header-menu" />,
      label: t('header.home'),
      permission: 'view_home'
    },
    {
      href: routePaths.Events,
      icon: <SVGAssets.Regions className="w-6 h-7 header-menu" />,
      label: t('header.region'),
      permission: 'view_region'
    },
    {
      href: '/resources',
      icon: <SVGAssets.ResourcesMenu className="w-6 h-7 header-menu" />,
      label: t('header.resources'),
      permission: 'view_resources'
    },
    {
      href: routePaths.VolunteerMatch,
      icon: <SVGAssets.MatchMenu className="w-6 h-7 header-menu" />,
      label: t('header.matches'),
      permission: 'view_my_matches'
    },
    {
      href: routePaths.Matches,
      icon: <SVGAssets.MatchMenu className="w-6 h-7 header-menu" />,
      label: t('header.matches'),
      permission: 'view_matches',
      child: [
        {
          href: routePaths.Matches,
          label: t('sidebar.volunteerMatch')
        },
        {
          href: routePaths.VolunteerMatch,
          label: t('sidebar.myMatch')
        },
        {
          href: routePaths.ManualMatch,
          label: t('sidebar.findAMatch')
        }
      ]
    },
    {
      href: routePaths.Events,
      icon: <SVGAssets.EventsMenu className="w-6 h-7 header-menu" />,
      label: t('header.events'),
      permission: 'view_events'
    },

    {
      href: routePaths.Events,
      icon: <SVGAssets.Manage className="w-6 h-7 header-menu" />,
      label: t('header.manage'),
      permission: 'view_manage'
    },
    {
      href: routePaths.Events,
      icon: <SVGAssets.Settings className="w-6 h-7 header-menu" />,
      label: t('header.settings'),
      permission: 'view_settings'
    },
    {
      href: routePaths.VolunteerList,
      icon: <SVGAssets.Settings className="w-6 h-7 header-menu" />,
      label: t('sidebar.manageAdmin'),
      permission: 'manage_admin'
    }
  ];

  const [activeItem, setActiveItem] = useState('');

  useEffect(() => {
    const currentPath = window.location.pathname;

    const foundItem = menuItems.find(item => {
      if (item.href === '#' && currentPath === '/') {
        return true;
      }
      return currentPath.includes(item.href);
    });

    if (foundItem) {
      setActiveItem(foundItem.label);
    } else {
      setActiveItem('');
    }
  }, [menuItems]);

  const dropdownItems = [
    { href: '/profile', label: t('header.profile') },
    { href: '/settings', label: t('header.settings') },
    { href: '/logout', label: t('header.logout') }
  ];

  const handleItemClick = (label: string, permission: string, item: any) => {
    dispatch(setTopMenuClicked(permission));
    setActiveItem(label);

    if (permission === 'view_resources') {
      dispatch(setResourceType('intro'));
    }
    navigate(`${item?.href}`);
  };
  const handleKeyDown = () => {};
  const handleClickOutside = (event: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      dispatch(setPermission(''));
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="navbar volunteer-head bg-white shadow-normal w-full py-2 lg:py-4 px-5 md:px-12">
      <SVGAssets.Menu
        className="w-8 mr-5 lg:hidden"
        onClick={toggleMenu}
        aria-label="Expand"
      />
      <div className="flex-1 cursor-pointer">
        <a href="/dashboard" data-testid="logo-clickable" id="logo-clickable">
          <SVGAssets.Logo className="w-12 h-12" />
        </a>
      </div>
      <nav className="flex-none gap-2">
        <div
          className="main-nav-sec mr-5 md:mr-12"
          id="mainNav"
          data-testid="mainNav">
          <ul>
            {menuItems.map(item => (
              <>
                {item.permission &&
                  usePermissions.includes(item.permission) && (
                    <div className="flex flex-col">
                      <li
                        key={item?.href}
                        onMouseEnter={() => {
                          dispatch(setPermission(item?.permission));
                        }}
                        className={`${activeItem === item.label ? 'active' : ''}`}>
                        <a
                          href={'#'}
                          onClick={() =>
                            handleItemClick(item.label, item?.permission, item)
                          }
                          className={`${activeItem === item.label ? '!font-semibold' : ''}`}>
                          <span>{item.icon}</span>
                          {item.label}
                        </a>
                      </li>

                      {item?.child?.length > 0 &&
                        item?.permission === activePermission && (
                          <div className="relative " ref={dropdownRef}>
                            <div className="absolute   mt-2 top-full left-0  w-40 bg-white border border-gray-300 rounded-md shadow-md z-50">
                              {item?.child?.map((data, i) => (
                                <p
                                  key={i + 1}
                                  role="none"
                                  onClick={() => {
                                    navigate(data?.href);
                                    dispatch(setPermission(''));
                                  }}
                                  className="px-2 py-2 hover:text-[var(--na-primary-color-hover)] text-sm  cursor-pointer">
                                  {data?.label}
                                </p>
                              ))}
                            </div>
                          </div>
                        )}
                    </div>
                  )}
              </>
            ))}
          </ul>
        </div>

        <div
          className="dropdown dropdown-end cursor-pointer"
          role="none"
          data-testid="profile"
          onClick={() => {
            dispatch(setTopMenuClicked('view_profile'));
            navigate('/profile');
          }}
          onKeyDown={() => handleKeyDown()}>
          <div className="">
            <div className=" flex items-center">
              <div className="hidden lg:block text-right mr-3">
                <h5 className="font-bold mb-0">
                  {(userInfoData?.results?.first_name ?? '') +
                    ' ' +
                    (userInfoData?.results?.last_name ?? '')}
                </h5>
                <h6 className="text-[--na-secondary-color]">
                  {userInfoData?.results?.groups?.name ?? ''}
                </h6>
              </div>
              <div className="relative">
                <SVGAssets.HeaderUser
                  data-testid="menuToggle"
                  className="!w-14 !h-14 user-icon rounded-full profile-icon"
                />
                <Avatar
                  alt={`${userInfoData?.results?.first_name}`}
                  className="absolute inset-0 w-10 h-10 top-2 left-2 user-icon rounded-full profile-icon"
                  src={userInfoData?.results?.profile?.profile_pic}
                  isHeader={true}
                />
              </div>
            </div>
          </div>
          <ul
            className="mt-3 z-0 p-2 shadow menu menu-sm dropdown-content bg-base-100 rounded-box w-52"
            id="sideNav">
            {dropdownItems.map(item => (
              <li key={item?.href}>
                <a href={item.href}>{item.label}</a>
              </li>
            ))}
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Header;
