import React from 'react';
import { SVGAssets } from '@business/assets/vectors';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const Page404 = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="app-404-page" data-testid="app-404-page">
      <div className="w-full flex justify-center items-center">
      <><SVGAssets.Page404
        data-testid="page-404-image"
        className="page-404-image"
      /></>
      </div>
      <div className="w-full flex  justify-center items-center mb-4 md:flex gap-5 mt-4 pt-4">
        <button
          data-testid="redirect-btn-base"
          onClick={() => navigate('/')}
          className="button button-transparent border-primary primary-color rounded-full !px-8 py-1.5 mt-3 md:mt-0">
         {t("appError.backToHome", "Back To Home")} 
        </button>
      </div>
    </div>
  );
};

export default Page404;
