import { Images } from '@business/assets/images';

export const AppLoaderLay = () => {
  return (
    <div className="z-[99999] fixed top-0 left-0 w-screen h-screen flex align-center justify-center select-none overflow-none bg-white/75">
      <img
        src={Images.Loader}
        height={200}
        width={200}
        alt="loadingIcon"
        className="object-contain"
      />
    </div>
  );
};
