export const routePaths = {
  SignIn: '/sign-in',
  SignUp: '/sign-up',
  SignUpStepTwo: '/sign-up/step-two',
  SignUpStepThree: '/sign-up/step-three',
  SignUpStepFour: '/sign-up/step-four',
  SignUpComplete: '/sign-up/complete',
  Dashboard: '/dashboard',
  ForgotPassword: '/forgot-password',
  ForceResetPassword: '/force-reset-password',
  ResetPassword: '/reset-password',
  Training: '/training',
  Preferences: '/preference',
  Schedules: '/schedules',
  LocalAreaCreation: '/local-area-creation',
  Matches: '/matches',
  Events: '/events',
  Volunteer: '/volunteer',
  Home: '/home',
  Profile: '/profile',
  Contacts: '/contacts',
  RequestorInformation: '/information',
  Gallery: '/gallery',
  MealRequests: '/request',
  FindMatch: '/find-match',
  Resources: '/resources',
  District: '/district',
  CreateUser: '/create-user',
  AreaCreation: '/area-creation',
  CountryList: '/country',
  CountryDetails: '/country/view-more',
  CreateAccount: '/create-account',
  CreateCountry: '/country/add-country',
  UploadState: '/states/upload-states',
  StateList: '/states',
  CreateState: '/states/create-states',
  StateDetails: '/states/view-more',
  AuthFlow: '/auth-flow',
  UploadZipCode: '/zip-code/upload',
  ListZipCode: '/zip-code/list',
  LocalArea: '/local-area',
  LocalAreaDetails: '/local-area/view-more',
  CreateLocalArea: '/local-area/add',
  FAQ: '/faq',
  AddVolunteer: '/volunteer/add',
  VolunteerList: '/volunteer/list',
  VolunteerPending: '/volunteer/pending-list',
  VolunteerDeleted: '/volunteer/deleted-list',
  VolunteerPaused: '/volunteer/paused-list',
  VolunteerProfile: '/volunteer/profile',
  DownloadCenter: '/download-center',
  CreateMealRequest: '/add-meal',
  ManageResource: '/manage-resource',
  ListFaq: '/list-faq',
  VolunteerMatch: '/volunteer-matches',
  ManualMatch: '/manual-match'
};
