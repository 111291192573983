import React, { useState, useMemo, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { signOut } from 'aws-amplify/auth';
import { useDispatch, useSelector } from 'react-redux';
import { SVGAssets } from '@business/assets/vectors';
import { routePaths } from '@utils/constants/routePaths';
import { setToken } from '@ports/common/sessionStateSlice';
import { useNavigate } from 'react-router-dom';
import { SidebarProps } from '@business/interfaces/sidebar';
import { getUserData } from '@ports/common/userStateSlice';
import {
  getMenuItems,
  getResource,
  getResourceType,
  getTopMenuClicked,
  setMenuItems,
  setResource,
  setResourceType
} from '@ports/common/sideBarStateSlice';
import { useAccountDetailsTutorialListQuery } from '@ports/internal-services/user/userSlice';

import { useContentManagerCategoryListQuery } from '@ports/internal-services/resource/resourceApiSlice';
import { menuItem } from '@utils/constants/globals';
import {
  resetVolunteer,
  setPendingList
} from '@ports/common/volunteerStateSlice';
import { AppLoaderLay } from '../AppLoaderLazy';
import { setCountry } from '@ports/common/localareaDetailsStateSlice';
import { resetMySchedule } from '@ports/common/scheduleStateSlice';
import { persister } from '@ports/common/store';

export const Sidebar: React.FC<SidebarProps> = ({ isAdmin }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetails = useSelector(getUserData);
  const menuItems: any = useSelector(getMenuItems);
  const topMenu = useSelector(getTopMenuClicked);
  const recourseType = useSelector(getResourceType);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const resourceData = useSelector(getResource);
  const usePermissions = useMemo(() => {
    return (
      userDetails?.results?.permissions?.map(
        (permission: any) => permission?.codename
      ) || []
    );
  }, [userDetails]);
  const { data: faqCategory } = useContentManagerCategoryListQuery({});
  const { data: accountData, refetch } = useAccountDetailsTutorialListQuery({
    groupAccessName: recourseType
  });

  useEffect(() => {
    if (topMenu !== 'view_resources') {
      const updatedMenuItems = (
        menuItems?.length > 0 ? menuItems : menuItem
      )?.map((item: any) => ({
        ...item,
        selected:
          (item?.child?.length > 0 &&
            item?.child?.some(
              (childItem: any) => childItem.href === window.location.pathname
            )) ||
          item?.urls?.includes(window.location.pathname),
        child: item?.child?.map((childItem: any) => ({
          ...childItem,
          selected: childItem.href === window.location.pathname
        }))
      }));
      dispatch(setMenuItems(updatedMenuItems));
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (faqCategory?.results?.length > 0) {
      const childItem = faqCategory?.results?.map(item => {
        return {
          no: item?.uuid,
          label: item?.name,
          href: routePaths.FAQ,
          selected: false,
          permission: 'view_faq'
        };
      });
      const updatedMenuItems = (
        menuItems?.length > 0 ? menuItems : menuItem
      )?.map((item: any) => ({
        ...item,
        child: item?.label === 'faq' ? childItem : item?.child
      }));
      dispatch(setMenuItems(updatedMenuItems));
    }
  }, [faqCategory]);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  useEffect(() => {
    refetch();
  }, []);
  useEffect(() => {
    if (recourseType) {
      const recourseTypeData = menuItems?.find(
        (item: any) => item.resourceType === recourseType
      )?.child;
      if (recourseTypeData?.length === 0 || !recourseTypeData) {
        refetch();
      }
    }
  }, [recourseType]);

  const handleItemClick = async (label: string, href: string) => {
    let updatedMenuItems: any[];
    if (label === 'sidebar.signOut') {
      setIsLoading(true);
      await signOut();
      dispatch(setToken({ token: null }));
      dispatch(setMenuItems([]));
      dispatch(setCountry(''));
      localStorage.clear();
      sessionStorage.clear();
      navigate(routePaths.SignIn);
      await persister?.pause();
      persister?.flush().then(async () => {
        await persister?.purge();
        return;
      });
      setIsLoading(false);

    } else {
      const resourceType = menuItem?.find(
        item => item.label === label && item.type
      )?.resourceType;
      dispatch(setResourceType(resourceType ?? ''));
      updatedMenuItems = menuItems?.map((item: any) => ({
        ...item,
        selected: item.label === label,
        child: item?.child?.map((childItem: any, index: number) => ({
          ...childItem,
          selected:
            childItem.label === label ? childItem.label === label : index === 0
        }))
      }));
      dispatch(setMenuItems(updatedMenuItems));
      navigate(href);
    }
  };
  const resourceChange = (tempIndex: number, childData: any) => {
    if (tempIndex !== -1) {
      const resultArray = menuItems?.length > 0 ? menuItems : menuItem;
      const tempArray = resultArray?.map((item: any, index: number) =>
        index === tempIndex
          ? { ...item, child: childData, selected: true }
          : { ...item, selected: false }
      );
      const selectedItem = childData?.find((item: any) => item.selected);
      const selectedItemIndex = childData?.findIndex(
        (item: any) => item.selected
      );
      if (accountData?.results?.length > 0) {
        const selectedResource = accountData?.results?.find(
          item => item?.name === selectedItem?.label
        );
        if (selectedResource) {
          dispatch(
            setResource({
              type: selectedResource?.file?.content_type,
              url: selectedResource?.file?.url,
              title: selectedResource?.name,
              index: selectedItemIndex + 1,
              uuid: selectedResource?.uuid,
              resource: recourseType
            })
          );
        }
      }

      dispatch(setMenuItems(tempArray));
    }
  };
  useEffect(() => {
    if (
      topMenu === 'view_resources' &&
      window.location.pathname !== '/faq' &&
      resourceData?.origin !== 'dashboard'
    ) {
      const tempIndex = menuItem?.findIndex(
        (item: any) => item?.resourceType === recourseType
      );

      const childItem = menuItem?.[tempIndex]?.child?.find(
        (item: any) => item?.selected
      );
      const childData = accountData?.results?.map(
        (item: any, index: number) => ({
          no: index + 40,
          label: item?.name,
          href: routePaths.Resources,
          selected: childItem ? childItem?.label === item?.name : index === 0,
          permission: 'view_resources'
        })
      );
      resourceChange(tempIndex, childData);
    }
  }, [accountData, recourseType, topMenu]);

  function sideNavMenu() {
    const element2 = document.getElementById('sideNav');
    const element3 = document.getElementById('sideNavMenu');
    element2.classList.toggle('sideNav-toggle');
    element3.classList.toggle('rotate-img');
    setIsVisible(!isVisible);
  }
  let sideBarTitle = '';

  switch (topMenu) {
    case 'view_resources':
      sideBarTitle = t('header.resources');
      break;
    case 'manage_admin':
      sideBarTitle = t('sidebar.manage');
      break;
    default:
      sideBarTitle = t('sidebar.title');
      break;
  }
  return (
    <Fragment>
      {isLoading && <AppLoaderLay />}
      <div className="w-full lg:w-2/12 mb-3 lg:mb-0">
        <div className="bg-white rounded-lg w-full h-full lg:w-11/12">
          <div className="flex justify-between items-center border-b-primary">
            <h4 className="secondary-font color-secondary-hover p-4">
              {sideBarTitle}
            </h4>
            <SVGAssets.Expand
              className="w-5 mr-5 lg:hidden cursor-pointer"
              id="sideNavMenu"
              data-testid="sideNavMenu"
              onClick={sideNavMenu}
            />
          </div>
          <ul
            className={`nav hidden lg:block ${isVisible ? 'sideNav-toggle' : ''}`}
            id="sideNav"
            data-testid="sideNav">
            {menuItems
              ?.filter((item: any) => {
                if (
                  item?.permission === 'signOut' &&
                  topMenu === 'view_profile'
                ) {
                  return true;
                }
                return item?.type === topMenu;
              })
              ?.map((item: any, i: number) => (
                <>
                  {item.permission &&
                    (usePermissions.includes(item.permission) ||
                      item?.permission === 'signOut') && (
                      <Fragment key={i + 1}>
                        <li
                          key={item.label}
                          role="none"
                          onClick={() =>
                            handleItemClick(item.label, item?.href)
                          }
                          className={`cursor-pointer ${item.selected ? 'active font-bold' : 'font-bold text-[var(--na-lite-gray4)]'} flex`}>
                          <p
                            className={`w-full text-left text-sm font-normal `}>
                            {t(item.label)}
                          </p>
                          {(item?.child?.length > 0 ||
                            item?.type === 'view_resources') && (
                              <>
                                {menuItems?.some(
                                  (items: any) =>
                                    items.label === item?.label && items.selected
                                ) ? (
                                  <SVGAssets.ArrowOpen className="w-4 h-4" />
                                ) : (
                                  <SVGAssets.ArrowClose className="w-4 h-4" />
                                )}
                              </>
                            )}
                        </li>
                        {item?.selected && item?.child?.length > 0 && (
                          <div className="max-h-40 overflow-y-auto">
                            {item?.child?.map((items: any, index: number) => (
                              <div
                                className={`${items.selected ? 'active font-bold' : 'font-bold text-[var(--na-lite-gray4)]'} text-sm pl-4`}
                                key={index + 1}>
                                <li
                                  role="none"
                                  className="cursor-pointer"
                                  onClick={() => {
                                    if (items.label === 'sidebar.addUser') {
                                      dispatch(
                                        setPendingList({ pendingList: false })
                                      );
                                      dispatch(resetVolunteer());
                                    } else if (
                                      items.label === 'sidebar.schedule'
                                    ) {
                                      dispatch(resetMySchedule());
                                    }
                                    const updatedMenuItems = menuItems.map(
                                      (menuItemData: any) =>
                                        menuItemData?.label === item.label
                                          ? {
                                            ...menuItemData,

                                            child: menuItemData?.child?.map(
                                              (itemData: any) => ({
                                                ...itemData,
                                                selected:
                                                  itemData.label ===
                                                  items?.label
                                              })
                                            )
                                          }
                                          : menuItemData
                                    );
                                    if (accountData?.results?.length > 0) {
                                      const childItem =
                                        accountData?.results?.find(
                                          (childItem: any) =>
                                            childItem?.name === items?.label
                                        );
                                      if (childItem) {
                                        dispatch(
                                          setResource({
                                            type: childItem?.file?.content_type,
                                            url: childItem?.file?.url,
                                            title: childItem?.name,
                                            index: index + 1,
                                            uuid: childItem?.uuid,
                                            resource: recourseType,
                                            origin: ''
                                          })
                                        );
                                      }
                                    }
                                    dispatch(setMenuItems(updatedMenuItems));
                                    navigate(items.href);
                                  }}>
                                  {t(items?.label)}
                                </li>
                              </div>
                            ))}
                          </div>
                        )}
                      </Fragment>
                    )}
                </>
              ))}
          </ul>
        </div>
      </div>
    </Fragment>
  );
};
