import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import type { RootState } from './store';
export type appLoadingStateType = {
  isLoading: boolean;
};

const initialState: appLoadingStateType = { isLoading: false };

export const appLoadingStateSlice = createSlice({
  name: 'appLoadingStateSlice',
  initialState,
  reducers: {
    setIsAppLoading: (state, action) => {
      state.isLoading = action.payload.isLoading;
    },
    resetApploadingState: () => {
      return initialState;
    }
  },
  extraReducers: build => {
    /*
     *
     *  This function is used to intercept all API calls across the app and set the isLoading state
     *  which is used to show the loader in AppLoader.
     *
     */
    const allApis: any = {};
    const ignoreList = [
      'userUsersList',
      'regionZipcodesList',
      'regionZipcodesUploadFile',
      'regionStateUploadFile'
    ];
    const filteredApiList = Object.keys(allApis).filter(
      item => !ignoreList.includes(item)
    );
    const isAnyApiPending = isAnyOf(
      ...filteredApiList.map((item: string) => allApis[item].matchPending)
    );
    const isAnyApiFulfilledOrRejected = isAnyOf(
      ...filteredApiList.map((item: string) => allApis[item].matchFulfilled),
      ...filteredApiList.map((item: string) => allApis[item].matchRejected)
    );

    build.addMatcher(isAnyApiPending, (state, action) => {
      state.isLoading = true;
    });
    build.addMatcher(isAnyApiFulfilledOrRejected, (state, action) => {
      const isAnyStillPending = filteredApiList.some((item: string) => {
        return allApis[item].matchPending(action);
      });

      if (!isAnyStillPending) {
        state.isLoading = false;
      }
    });
  }
});

export const { setIsAppLoading, resetApploadingState } = appLoadingStateSlice.actions;
export const getAppLoadingState = (state: RootState) =>
  state.appLoadingStateSlice.isLoading;
