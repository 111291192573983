import { store } from '@ports/common/store';
import { fetchAuthSession } from 'aws-amplify/auth';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { defaultStorage, sessionStorage } from 'aws-amplify/utils';

export const getTypeOFRemember = () => {
  const rememberMe = store?.getState()?.authStateSlice?.rememberMe;
  if (rememberMe) {
    cognitoUserPoolsTokenProvider.setKeyValueStorage(defaultStorage);
  } else {
    cognitoUserPoolsTokenProvider.setKeyValueStorage(sessionStorage);
  }
};
export const getCognitoToken = async () => {
  try {
    getTypeOFRemember();
    const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};

    return {
      accessToken: accessToken?.toString(),
      idToken: idToken?.toString()
    };
  } catch (e: any) {
    return '';
  }
};
