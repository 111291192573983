import React from 'react';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { PersistGate } from 'redux-persist/integration/react';
import { ComponentProviderProps } from '@business/interfaces/component-provider';
import { persister, store } from '@ports/common/store';
import i18n from '@business/localization/language/i18n';
import { AppLoader } from '@business/workflows/ComponentUI/AppLoader';
import { AuthGuard } from '../AuthGuard';

/*
 * This component is used as wrapper to generate unit tests
 */
export function ComponentProvider({
  children
}: Readonly<ComponentProviderProps>) {
  return (
    // <RouteMiddleware>
    <Provider store={store}>
      <PersistGate loading={''} persistor={persister}>
        <I18nextProvider i18n={i18n} defaultNS={'translation'}>
          <AppLoader />
          <AuthGuard>{children}</AuthGuard>
        </I18nextProvider>
      </PersistGate>
    </Provider>
    // </RouteMiddleware>
  );
}
