import { FC, ReactNode, useEffect, useRef } from 'react';
import { AlertProps } from '@business/interfaces/alert';
import { SVGAssets } from '@business/assets/vectors';
import { Button } from '../Button';

const getIcon = (type: string) => {
  let icon: ReactNode;
  switch (type) {
    case 'info':
      icon = (
        <SVGAssets.AlertInfo
          width={30}
          height={30}
          data-testid="info"
          style={{ color: 'var(--na-info)' }}
        />
      );
      break;
    case 'warning':
      icon = (
        <SVGAssets.AlertWarning
          width={30}
          height={30}
          data-testid="warning"
          style={{ color: 'var(--na-warning)' }}
        />
      );
      break;
    case 'success':
      icon = (
        <SVGAssets.AlertCheck
          width={30}
          height={30}
          data-testid="success"
          style={{ color: 'var(--na-success)' }}
        />
      );
      break;
    case 'error':
    case 'failed':
      icon = (
        <SVGAssets.AlertError
          width={30}
          height={30}
          data-testid="error"
          style={{ color: 'var(--na-critical)' }}
        />
      );
      break;
    default:
      icon = <span data-testid="no-icon" />;
  }
  return icon;
};

export const Alert: FC<AlertProps> = ({
  type = 'success',
  heading,
  subHeading,
  wrapperClass = '',
  contentClass = 'w-full',
  onClose = () => { },
  isClosable = true,
  isFocusable = true,
  autoHide = true,
  autoHideDuration = 10000,
  onClick,
  customLeftIcon,
  customRightIcon,
  children
}) => {
  const alertRef = useRef(null);
  const icon = customLeftIcon || getIcon(type);
  useEffect(() => {
    if (isFocusable) {
      alertRef.current.scrollIntoView({ block: 'center' });
    }
    if (autoHide) {
      setTimeout(() => {
        onClose();
      }, autoHideDuration);
    }
  }, []);

  return (
    <span
      role="none"
      ref={alertRef}
      data-testid={`alert-box-${type}`}
      className={`th-badge w-max !rounded-xl ${type}-message ${wrapperClass} ${typeof onClick !== 'undefined' ? 'cursor-pointer' : ''
        }`}
      onClick={onClick}>
      <div
        className={`flex items-center justify-between flex-row ${contentClass}`}>
        <div
          className={`flex ${subHeading ? 'items-start' : 'items-center'
            } text-width`}>
          <div className="w-[30px]">{icon}</div>
          <div className="text-left ml-2 text-sm">
            <div>{heading}</div>
            {subHeading && (
              <div className="text-sm">
                <span>{subHeading}</span>
              </div>
            )}
            {/* {typeof children === 'object' ? children : <div>{children}</div>} */}
            {children}
          </div>
        </div>

        {customRightIcon ||
          (isClosable && (
            <Button
              btnStyle="link"
              testid="close-button"
              className="cursor-pointer alert-close ml-5 "
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                onClose();
              }}
              data-testid="close-icon">
              <SVGAssets.CloseBtn height={15} width={15} />
            </Button>
          ))}
      </div>
    </span>
  );
};
