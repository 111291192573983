import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';

export type authZipType = {
  sortName: string;
};

const initialState: authZipType = {
  sortName: ''
};

export const zipcodeStateSlice = createSlice({
  name: 'zipcodeStateSlice',
  initialState,
  reducers: {
    setSortName: (state, action) => {
      state.sortName = action.payload;
    },
    resetSortName: () => {
      return initialState;
    }
  }
});

export const { setSortName, resetSortName } = zipcodeStateSlice.actions;
export const getSortName = (state: RootState) =>
  state.zipcodeStateSlice.sortName;
