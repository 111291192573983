import { RefObject, useEffect } from 'react';

export function useOutsideClickHandler(
  ref: RefObject<HTMLElement>,
  handleClick: () => void
) {
  useEffect(() => {
    /**
     * Action on outside of element
     */
    function handleClickOutside(event?: MouseEvent | null) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        handleClick();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}
