import { createSlice } from '@reduxjs/toolkit';
import { SessionStateType } from '@business/entities/session';
import type { RootState } from './store';

const initialState: SessionStateType = {
  isAdmin: false,
  userPermissions: [],
  notificationType: false
};

export const sessionStateSlice = createSlice({
  name: 'sessionStateSlice',
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload.token;
      state.refreshToken = action.payload.refreshToken;
    },
    setFirstLogin: (state, action) => {
      state.firstLogin = action.payload;
    },
    resetSessionState: () => {
      return initialState;
    },

    setIsAdmin: (state, action) => {
      state.isAdmin = action.payload;
    },
    setUserPermissions: (state, action) => {
      state.userPermissions = action.payload;
    },
    logoutUser: state => {
      state.isAdmin = false;
      state.token = null;
      state.refreshToken = null;
      state.userPermissions = [];
    },
    setNotificationType: (state, action) => {
      state.notificationType = action.payload;
    }
  }
});

export const {
  setToken,
  setFirstLogin,
  resetSessionState,
  setIsAdmin,
  setUserPermissions,
  logoutUser,
  setNotificationType
} = sessionStateSlice.actions;
export const getToken = (state: RootState) => state.sessionStateSlice?.token;
export const getRefreshToken = (state: RootState) =>
  state.sessionStateSlice?.refreshToken;
export const getFirstLogin = (state: RootState) =>
  state.sessionStateSlice?.firstLogin;
export const getIsAdmin = (state: RootState) =>
  state.sessionStateSlice?.isAdmin;
export const getUserPermissions = (state: RootState) =>
  state.sessionStateSlice?.userPermissions;
export const getNotificationType = (state: RootState) =>
  state.sessionStateSlice?.notificationType;
