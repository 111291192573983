import { userApi as api } from './userApi';
export const addTagTypes = ['account_details', 'common', 'user'] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: build => ({
      accountDetailsTutorialList: build.query<
        AccountDetailsTutorialListApiResponse,
        AccountDetailsTutorialListApiArg
      >({
        query: queryArg => ({
          url: `/account_details/tutorial/`,
          params: {
            group_access__name: queryArg.groupAccessName,
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset
          }
        }),
        providesTags: ['account_details']
      }),
      accountDetailsTutorialUnwatchedTutorial: build.query<
        AccountDetailsTutorialUnwatchedTutorialApiResponse,
        AccountDetailsTutorialUnwatchedTutorialApiArg
      >({
        query: queryArg => ({
          url: `/account_details/tutorial/unwatched_tutorial/`,
          params: {
            group_access__name: queryArg.groupAccessName,
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset
          }
        }),
        providesTags: ['account_details']
      }),
      accountDetailsTutorialWatchedTutorial: build.mutation<
        AccountDetailsTutorialWatchedTutorialApiResponse,
        AccountDetailsTutorialWatchedTutorialApiArg
      >({
        query: queryArg => ({
          url: `/account_details/tutorial/watched_tutorial/`,
          method: 'POST',
          body: queryArg.watchTutorial
        }),
        invalidatesTags: ['account_details']
      }),
      commonCredentialsList: build.query<
        CommonCredentialsListApiResponse,
        CommonCredentialsListApiArg
      >({
        query: () => ({ url: `/common/credentials/` }),
        providesTags: ['common']
      }),
      userCallbackCreate: build.mutation<
        UserCallbackCreateApiResponse,
        UserCallbackCreateApiArg
      >({
        query: queryArg => ({
          url: `/user/callback/`,
          method: 'POST',
          body: queryArg.body
        }),
        invalidatesTags: ['user']
      }),
      userNotifiedfromList: build.query<
        UserNotifiedfromListApiResponse,
        UserNotifiedfromListApiArg
      >({
        query: queryArg => ({
          url: `/user/notifiedfrom/`,
          params: {
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset
          }
        }),
        providesTags: ['user']
      }),
      userPendingUsersList: build.query<
        UserPendingUsersListApiResponse,
        UserPendingUsersListApiArg
      >({
        query: queryArg => ({
          url: `/user/pending_users/`,
          params: {
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset,
            first_name: queryArg.firstName,
            last_name: queryArg.lastName,
            email: queryArg.email,
            status: queryArg.status
          }
        }),
        providesTags: ['user']
      }),
      userPendingUsersRegister: build.mutation<
        UserPendingUsersRegisterApiResponse,
        UserPendingUsersRegisterApiArg
      >({
        query: queryArg => ({
          url: `/user/pending_users/register/`,
          method: 'POST',
          body: queryArg.cognitoUser
        }),
        invalidatesTags: ['user']
      }),
      userPendingUsersResendVerification: build.mutation<
        UserPendingUsersResendVerificationApiResponse,
        UserPendingUsersResendVerificationApiArg
      >({
        query: queryArg => ({
          url: `/user/pending_users/resend_verification/`,
          method: 'POST',
          body: queryArg.cognitoUser
        }),
        invalidatesTags: ['user']
      }),
      userPendingUsersRead: build.query<
        UserPendingUsersReadApiResponse,
        UserPendingUsersReadApiArg
      >({
        query: queryArg => ({ url: `/user/pending_users/${queryArg.id}/` }),
        providesTags: ['user']
      }),
      userPermissionsList: build.query<
        UserPermissionsListApiResponse,
        UserPermissionsListApiArg
      >({
        query: queryArg => ({
          url: `/user/permissions/`,
          params: {
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset
          }
        }),
        providesTags: ['user']
      }),
      userSignupflowRead: build.query<
        UserSignupflowReadApiResponse,
        UserSignupflowReadApiArg
      >({
        query: queryArg => ({ url: `/user/signupflow/${queryArg.uuid}/` }),
        providesTags: ['user']
      }),
      userSignupflowPartialUpdate: build.mutation<
        UserSignupflowPartialUpdateApiResponse,
        UserSignupflowPartialUpdateApiArg
      >({
        query: queryArg => ({
          url: `/user/signupflow/${queryArg.uuid}/`,
          method: 'PATCH',
          body: queryArg.signupFlow
        }),
        invalidatesTags: ['user']
      }),
      userUserRegionDetailList: build.query<
        UserUserRegionDetailListApiResponse,
        UserUserRegionDetailListApiArg
      >({
        query: queryArg => ({
          url: `/user/user_region_detail/`,
          params: {
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset
          }
        }),
        providesTags: ['user']
      }),
      userUserinfoList: build.query<
        UserUserinfoListApiResponse,
        UserUserinfoListApiArg
      >({
        query: queryArg => ({
          url: `/user/userinfo/`,
          params: {
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset
          }
        }),
        providesTags: ['user']
      }),
      userUsersCreate: build.mutation<
        UserUsersCreateApiResponse,
        UserUsersCreateApiArg
      >({
        query: queryArg => ({
          url: `/user/users/`,
          method: 'POST',
          body: queryArg.user
        }),
        invalidatesTags: ['user']
      }),
      userUsersDeleteMultiple: build.mutation<
        UserUsersDeleteMultipleApiResponse,
        UserUsersDeleteMultipleApiArg
      >({
        query: queryArg => ({
          url: `/user/users/delete_multiple/`,
          method: 'POST',
          body: queryArg.body
        }),
        invalidatesTags: ['user']
      }),
      userUsersLite: build.query<UserUsersLiteApiResponse, UserUsersLiteApiArg>(
        {
          query: queryArg => ({
            url: `/user/users/lite/`,
            params: {
              search: queryArg.search,
              ordering: queryArg.ordering,
              limit: queryArg.limit,
              offset: queryArg.offset,
              state__uuid: queryArg.stateUuid,
              district__uuid: queryArg.districtUuid,
              country__uuid: queryArg.countryUuid
            }
          }),
          providesTags: ['user']
        }
      ),
      userUsersVolunteerListMatches: build.query<
        UserUsersVolunteerListMatchesApiResponse,
        UserUsersVolunteerListMatchesApiArg
      >({
        query: queryArg => ({
          url: `/user/users/volunteer_list_matches/`,
          params: {
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset
          }
        }),
        providesTags: ['user']
      }),
      userUsersRead: build.query<UserUsersReadApiResponse, UserUsersReadApiArg>(
        {
          query: queryArg => ({ url: `/user/users/${queryArg.uuid}/` }),
          providesTags: ['user']
        }
      ),
      userUsersPartialUpdate: build.mutation<
        UserUsersPartialUpdateApiResponse,
        UserUsersPartialUpdateApiArg
      >({
        query: queryArg => ({
          url: `/user/users/${queryArg.uuid}/`,
          method: 'PATCH',
          body: queryArg.user
        }),
        invalidatesTags: ['user']
      }),
      userUsersDelete: build.mutation<
        UserUsersDeleteApiResponse,
        UserUsersDeleteApiArg
      >({
        query: queryArg => ({
          url: `/user/users/${queryArg.uuid}/`,
          method: 'DELETE'
        }),
        invalidatesTags: ['user']
      })
    }),
    overrideExisting: false
  });
export { injectedRtkApi as enhancedApi };
export type AccountDetailsTutorialListApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: TutorialRead[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type AccountDetailsTutorialListApiArg = {
  /** group_access__name */
  groupAccessName?: string;
  /** file__asset_type */
  fileAssetType?: string;
  /** file__content_type */
  fileContentType?: string;
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type AccountDetailsTutorialUnwatchedTutorialApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: TutorialRead[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type AccountDetailsTutorialUnwatchedTutorialApiArg = {
  /** group_access__name */
  groupAccessName?: string;
  /** file__asset_type */
  fileAssetType?: string;
  /** file__content_type */
  fileContentType?: string;
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type AccountDetailsTutorialWatchedTutorialApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: TutorialRead[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type AccountDetailsTutorialWatchedTutorialApiArg = {
  watchTutorial: WatchTutorial;
};
export type CommonCredentialsListApiResponse =
  /** status 200 Custom Response */ {
    results: {
      token?: string;
      key?: string;
      iv?: string;
    };
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type CommonCredentialsListApiArg = void;
export type UserCallbackCreateApiResponse = /** status 200 Custom Response */ {
  results: {
    uuid?: string;
    username?: string;
    email?: string;
    profile_uuid?: number;
  };
  ok?: boolean;
  message?: {
    text?: string | null;
    code?: number;
    args?: object | null;
  };
};
export type UserCallbackCreateApiArg = {
  body: {
    /** Username of the new user */
    username: string;
    /** User email */
    email: string;
  };
};
export type UserNotifiedfromListApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: NotifiedFromRead[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type UserNotifiedfromListApiArg = {
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type UserPendingUsersListApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: CognitoUser[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type UserPendingUsersListApiArg = {
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
  /** Filter users by first name */
  firstName?: string;
  /** Filter users by last name */
  lastName?: string;
  /** Filter users by email */
  email?: string;
  /** Filter users by status */
  status?: string;
};
export type UserPendingUsersRegisterApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: CognitoUser[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type UserPendingUsersRegisterApiArg = {
  cognitoUser: CognitoUser;
};
export type UserPendingUsersResendVerificationApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: CognitoUser[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type UserPendingUsersResendVerificationApiArg = {
  cognitoUser: CognitoUser;
};
export type UserPendingUsersReadApiResponse =
  /** status 200 Custom Response */ {
    results: CognitoUser;
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type UserPendingUsersReadApiArg = {
  id: string;
};
export type UserPermissionsListApiResponse = /** status 200 Custom Response */ {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results: PermissionRead[];
  ok?: boolean;
  message?: {
    text?: string | null;
    code?: number;
    args?: object | null;
  };
};
export type UserPermissionsListApiArg = {
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type UserSignupflowReadApiResponse = /** status 200 Custom Response */ {
  results: SignupFlowRead;
  ok?: boolean;
  message?: {
    text?: string | null;
    code?: number;
    args?: object | null;
  };
};
export type UserSignupflowReadApiArg = {
  uuid: string;
};
export type UserSignupflowPartialUpdateApiResponse =
  /** status 200 Custom Response */ {
    results: SignupFlowRead;
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type UserSignupflowPartialUpdateApiArg = {
  uuid: string;
  signupFlow: SignupFlowUpdate;
};
export type UserUserRegionDetailListApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: UserRegionDetailRead;
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type UserUserRegionDetailListApiArg = {
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type UserUserinfoListApiResponse = /** status 200 Custom Response */ {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results: UserInfoRead;
  ok?: boolean;
  message?: {
    text?: string | null;
    code?: number;
    args?: object | null;
  };
};
export type UserUserinfoListApiArg = {
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type UserUsersCreateApiResponse = /** status 200 Custom Response */ {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results: UserRead[];
  ok?: boolean;
  message?: {
    text?: string | null;
    code?: number;
    args?: object | null;
  };
};
export type UserUsersCreateApiArg = {
  user: User;
};
export type UserUsersDeleteMultipleApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: UserRead[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type UserUsersDeleteMultipleApiArg = {
  body: {
    /** List of user UUIDs to delete */
    user_uuids: string[];
  };
};
export type UserUsersLiteApiResponse = /** status 200 Custom Response */ {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results: UserRead[];
  ok?: boolean;
  message?: {
    text?: string | null;
    code?: number;
    args?: object | null;
  };
};
export type UserUsersLiteApiArg = {
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
  /** User sort by state */
  stateUuid?: string;
  /** User sort by district */
  districtUuid?: string;
  /** User sort by country */
  countryUuid?: string;
};

export type UserUsersVolunteerListMatchesApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: UserRead[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type UserUsersVolunteerListMatchesApiArg = {
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type UserUsersReadApiResponse = /** status 200 Custom Response */ {
  results: UserRead;
  ok?: boolean;
  message?: {
    text?: string | null;
    code?: number;
    args?: object | null;
  };
};
export type UserUsersReadApiArg = {
  uuid: string;
};
export type UserUsersPartialUpdateApiResponse =
  /** status 200 Custom Response */ {
    results: UserRead;
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type UserUsersPartialUpdateApiArg = {
  uuid: string;
  user: User;
};
export type UserUsersDeleteApiResponse = /** status 200 Custom Response */ {
  results: UserRead;
  ok?: boolean;
  message?: {
    text?: string | null;
    code?: number;
    args?: object | null;
  };
};
export type UserUsersDeleteApiArg = {
  uuid: string;
};
export type Asset = {
  url?: string | null;
  file_name?: string | null;
  size?: number | null;
  content_type?: string | null;
  asset_type?: string | null;
};
export type AssetRead = {
  uuid?: string;
  url?: string | null;
  file_name?: string | null;
  size?: number | null;
  content_type?: string | null;
  asset_type?: string | null;
};
export type Tutorial = {
  name: string;
  description?: string;
  file: Asset;
  group_access?: string | null;
  ordering?: number | null;
  button_text?: string | null;
};
export type TutorialRead = {
  uuid?: string;
  name: string;
  description?: string;
  file: AssetRead;
  group_access?: string | null;
  ordering?: number | null;
  button_text?: string | null;
};
export type WatchTutorial = {
  user: string;
  tutorial: string;
  watched?: boolean | null;
};
export type NotifiedFrom = {
  name: string;
};
export type NotifiedFromRead = {
  uuid?: string;
  name: string;
};
export type CognitoUser = {
  username?: string;
  first_name: string;
  last_name: string;
  email: string;
  create_date?: string;
  modified_date?: string;
  status?: string;
};
export type Permission = {
  codename: string;
};
export type PermissionRead = {
  id?: number;
  codename: string;
};
export type GroupLite = {
  name: string;
};
export type GroupLiteRead = {
  uuid?: string;
  name: string;
};
export type ProfileList = {
  gender?: ('M' | 'F' | 'PNTS') | null;
  birth_date?: string | null;
  contact_number?: string | null;
  street_address?: string | null;
  city?: string | null;
  state?: string | null;
  country_origin?: string | null;
  completed_percentage?: number;
  zip_code?: string | null;
  profile_pic?: string;
  cover_pic?: string;
  volunteering_with?: boolean;
  mailing_address_same?: number;
  country_name?: string;
};
export type ProfileListRead = {
  uuid?: string;
  gender?: ('M' | 'F' | 'PNTS') | null;
  birth_date?: string | null;
  over_18?: string;
  contact_number?: string | null;
  street_address?: string | null;
  city?: string | null;
  state?: string | null;
  country_origin?: string | null;
  completed_percentage?: number;
  zip_code?: string | null;
  profile_pic?: string;
  cover_pic?: string;
  volunteering_with?: boolean;
  mailing_address_same?: number;
  country_name?: string;
};
export type MamaProfile = {
  cb_mamaavail?: ('Active' | 'Paused') | null;
  cb_mamastart?: string | null;
  cb_pausedate?: string | null;
  cb_mamapausenotes?: string | null;
  cb_mamafreq?: string | null;
  cb_mamacap?: number | null;
  cb_mamafillin?: string | null;
  cb_mamaradius?: number | null;
  cb_mamacooks?: string | null;
  cb_mamasmsok?: number | null;
  cb_mamaleader?: string | null;
  cb_mamalat?: string | null;
  cb_mamalon?: string | null;
  cb_mamatzone?: string | null;
  cb_mamamisc?: string | null;
  cb_mamasocial?: string | null;
  cb_mamamatches?: number | null;
  cb_mamadeliveries?: number | null;
  cb_mamalmnotes?: string | null;
  cb_mama1stmatch?: string | null;
  cb_hidedelivered?: string | null;
  cb_mamaradiustype?: string | null;
};
export type MamaProfileRead = {
  uuid?: string;
  cb_mamaavail?: ('Active' | 'Paused') | null;
  cb_mamastart?: string | null;
  cb_pausedate?: string | null;
  cb_mamapausenotes?: string | null;
  cb_mamafreq?: string | null;
  cb_mamacap?: number | null;
  cb_mamafillin?: string | null;
  cb_mamaradius?: number | null;
  cb_mamacooks?: string | null;
  cb_mamasmsok?: number | null;
  cb_mamaleader?: string | null;
  cb_mamalat?: string | null;
  cb_mamalon?: string | null;
  cb_mamatzone?: string | null;
  cb_mamamisc?: string | null;
  cb_mamasocial?: string | null;
  cb_mamamatches?: number | null;
  cb_mamadeliveries?: number | null;
  cb_mamalmnotes?: string | null;
  cb_mama1stmatch?: string | null;
  cb_hidedelivered?: string | null;
  cb_mamaradiustype?: string | null;
};
export type CommunicationPreferences = {
  latest_news_updates?: boolean;
  weekly_goodness_email?: boolean;
  text_notifications?: boolean;
};
export type OtherDetailsProfile = {
  cb_source?: string | null;
  cb_othersource?: string | null;
  cb_employer?: string | null;
  cb_organization?: string | null;
  cb_watchedvideofood?: number | null;
  cb_dateconfirmed?: string | null;
  cb_dateapproved?: string | null;
  cb_streetaddress?: string | null;
};
export type OtherDetailsProfileRead = {
  uuid?: string;
  cb_source?: string | null;
  cb_othersource?: string | null;
  cb_employer?: string | null;
  cb_organization?: string | null;
  cb_streetaddress?: string | null;
  cb_watchedvideofood?: number | null;
  cb_dateconfirmed?: string | null;
  cb_dateapproved?: string | null;
};
export type SignupFlow = {
  first_name?: string;
  last_name?: string;
  groups?: GroupLite;
  profile?: ProfileList;
  mamaprofile?: MamaProfile;
  communication_preferences?: CommunicationPreferences;
  other_details?: OtherDetailsProfile;
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  is_active?: boolean;
  date_joined?: string;
};
export type SignupFlowRead = {
  uuid?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  groups?: GroupLiteRead;
  profile: ProfileListRead;
  mamaprofile?: MamaProfileRead;
  communication_preferences?: CommunicationPreferences;
  other_details: OtherDetailsProfileRead;
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  is_active?: boolean;
  date_joined?: string;
};
export type SignupFlowUpdate = {
  first_name?: string;
  last_name?: string;
  groups?: string | null;
  profile?: ProfileList;
  mamaprofile?: MamaProfile;
  communication_preferences?: CommunicationPreferences;
  other_details?: OtherDetailsProfile;
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  is_active?: boolean;
  date_joined?: string;
};
export type SignupFlowUpdateRead = {
  uuid?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  groups?: string | null;
  profile: ProfileListRead;
  mamaprofile?: MamaProfileRead;
  communication_preferences?: CommunicationPreferences;
  other_details: OtherDetailsProfileRead;
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  is_active?: boolean;
  date_joined?: string;
};
export type UserRegionDetail = {
  zip: string;
  primary_city?: string | null;
  county?: string | null;
  area_codes?: string | null;
  state_code?: string;
  district?: string;
  state?: string;
  country?: string;
  local_leader?: string;
  regional_director?: string;
};
export type UserRegionDetailRead = {
  uuid?: string;
  zip: string;
  primary_city?: string | null;
  county?: string | null;
  area_codes?: string | null;
  state_code?: string;
  district?: string;
  state?: string;
  country?: string;
  local_leader?: string;
  regional_director?: string;
};
export type ProfileLite = {
  profile_pic: string;
  completed_percentage?: number;
  gender?: ('M' | 'F' | 'PNTS') | null;
};
export type ProfileLiteRead = {
  uuid?: string;
  profile_pic: string;
  completed_percentage?: number;
  gender?: ('M' | 'F' | 'PNTS') | null;
};
export type GroupLiteList = {
  name: string;
};
export type UserInfo = {
  email: string;
  first_name?: string;
  last_name?: string;
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  is_active?: boolean;
  profile: ProfileLite;
  groups: GroupLiteList;
};
export type UserInfoRead = {
  uuid?: string;
  email: string;
  first_name?: string;
  last_name?: string;
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  is_active?: boolean;
  profile: ProfileLiteRead;
  permissions?: PermissionRead[];
  groups: GroupLiteList;
  tutorials_completed?: string;
};
export type User = {
  first_name?: string;
  last_name?: string;
  email?: string;
  profile?: ProfileList;
  groups?: GroupLite;
  other_details?: OtherDetailsProfile;
  mamaprofile?: MamaProfile;
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  is_active?: boolean;
  block?: number;
};
export type UserRead = {
  uuid?: string;
  id?: number;
  first_name?: string;
  last_name?: string;
  email: string;
  date_joined?: string;
  profile: ProfileListRead;
  groups?: GroupLiteRead;
  other_details: OtherDetailsProfileRead;
  mamaprofile: MamaProfileRead;
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  is_active?: boolean;
  block?: number;
  last_login?: string | null;
};
export const {
  useAccountDetailsTutorialListQuery,
  useAccountDetailsTutorialUnwatchedTutorialQuery,
  useAccountDetailsTutorialWatchedTutorialMutation,
  useCommonCredentialsListQuery,
  useUserCallbackCreateMutation,
  useUserNotifiedfromListQuery,
  useUserPendingUsersListQuery,
  useUserPendingUsersRegisterMutation,
  useUserPendingUsersResendVerificationMutation,
  useUserPendingUsersReadQuery,
  useUserPermissionsListQuery,
  useUserSignupflowReadQuery,
  useUserSignupflowPartialUpdateMutation,
  useUserUserRegionDetailListQuery,
  useUserUserinfoListQuery,
  useUserUsersCreateMutation,
  useUserUsersDeleteMultipleMutation,
  useUserUsersLiteQuery,
  useUserUsersVolunteerListMatchesQuery,
  useUserUsersReadQuery,
  useUserUsersPartialUpdateMutation,
  useUserUsersDeleteMutation
} = injectedRtkApi;
