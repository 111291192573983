import { FC } from 'react';
import { ButtonGroupProps } from '@business/interfaces/button-group';
import { Button } from '../Button';

export const ButtonGroup: FC<ButtonGroupProps> = ({
  buttons,
  classNameOuter
}) => {
  let nextUniqueId = 0;
  const generateUniqueKey = () => {
    const uniqueKey = `button_${nextUniqueId}`;
    nextUniqueId += 1;
    return uniqueKey;
  };
  return (
    <fieldset className={classNameOuter} data-testid="button-group">
      {buttons.map(button => (
        <Button key={generateUniqueKey()} {...button} />
      ))}
    </fieldset>
  );
};
