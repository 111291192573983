import { resourceApi as api } from './resourceApi';
export const addTagTypes = [
  'account_details',
  'content_manager',
  'user'
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: build => ({
      accountDetailsTutorialList: build.query<
        AccountDetailsTutorialListApiResponse,
        AccountDetailsTutorialListApiArg
      >({
        query: queryArg => ({
          url: `/account_details/tutorial/`,
          params: {
            group_access__name: queryArg.groupAccessName,
            file__asset_type: queryArg.fileAssetType,
            file__content_type: queryArg.fileContentType,
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset
          }
        }),
        providesTags: ['account_details']
      }),
      accountDetailsTutorialCreate: build.mutation<
        AccountDetailsTutorialCreateApiResponse,
        AccountDetailsTutorialCreateApiArg
      >({
        query: queryArg => ({
          url: `/account_details/tutorial/`,
          method: 'POST',
          body: queryArg.tutorial
        }),
        invalidatesTags: ['account_details']
      }),
      accountDetailsTutorialRead: build.query<
        AccountDetailsTutorialReadApiResponse,
        AccountDetailsTutorialReadApiArg
      >({
        query: queryArg => ({
          url: `/account_details/tutorial/${queryArg.uuid}/`
        }),
        providesTags: ['account_details']
      }),
      accountDetailsTutorialUpdate: build.mutation<
        AccountDetailsTutorialUpdateApiResponse,
        AccountDetailsTutorialUpdateApiArg
      >({
        query: queryArg => ({
          url: `/account_details/tutorial/${queryArg.uuid}/`,
          method: 'PUT',
          body: queryArg.tutorial
        }),
        invalidatesTags: ['account_details']
      }),
      accountDetailsTutorialDelete: build.mutation<
        AccountDetailsTutorialDeleteApiResponse,
        AccountDetailsTutorialDeleteApiArg
      >({
        query: queryArg => ({
          url: `/account_details/tutorial/${queryArg.uuid}/`,
          method: 'DELETE'
        }),
        invalidatesTags: ['account_details']
      }),
      contentManagerCategoryList: build.query<
        ContentManagerCategoryListApiResponse,
        ContentManagerCategoryListApiArg
      >({
        query: queryArg => ({
          url: `/content_manager/category/`,
          params: {
            name: queryArg.name,
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset
          }
        }),
        providesTags: ['content_manager']
      }),
      userGroupsList: build.query<
        UserGroupsListApiResponse,
        UserGroupsListApiArg
      >({
        query: queryArg => ({
          url: `/user/groups/`,
          params: {
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset
          }
        }),
        providesTags: ['user']
      })
    }),
    overrideExisting: false
  });
export { injectedRtkApi as enhancedApi };
export type AccountDetailsTutorialListApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: TutorialRead[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type AccountDetailsTutorialListApiArg = {
  /** group_access__name */
  groupAccessName?: string;
  /** file__asset_type */
  fileAssetType?: string;
  /** file__content_type */
  fileContentType?: string;
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type AccountDetailsTutorialCreateApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: TutorialRead[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type AccountDetailsTutorialCreateApiArg = {
  tutorial: Tutorial;
};
export type AccountDetailsTutorialReadApiResponse =
  /** status 200 Custom Response */ {
    results: TutorialRead;
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type AccountDetailsTutorialReadApiArg = {
  uuid: string;
};
export type AccountDetailsTutorialUpdateApiResponse =
  /** status 200 Custom Response */ {
    results: TutorialRead;
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type AccountDetailsTutorialUpdateApiArg = {
  uuid: string;
  tutorial: Tutorial;
};
export type AccountDetailsTutorialDeleteApiResponse =
  /** status 200 Custom Response */ {
    results: TutorialRead;
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type AccountDetailsTutorialDeleteApiArg = {
  uuid: string;
};
export type ContentManagerCategoryListApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: CategoryRead[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type ContentManagerCategoryListApiArg = {
  /** name */
  name?: string;
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type UserGroupsListApiResponse = /** status 200 Custom Response */ {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results: GroupRead[];
  ok?: boolean;
  message?: {
    text?: string | null;
    code?: number;
    args?: object | null;
  };
};
export type UserGroupsListApiArg = {
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type Asset = {
  url?: string | null;
  file_name?: string | null;
  size?: number | null;
  content_type?: string | null;
  asset_type?: string | null;
};
export type AssetRead = {
  uuid?: string;
  url?: string | null;
  file_name?: string | null;
  size?: number | null;
  content_type?: string | null;
  asset_type?: string | null;
};
export type Tutorial = {
  name: string;
  description?: string;
  is_active?: boolean;
  file: Asset;
  group_access?: string | null;
  ordering?: number | null;
  button_text?: string | null;
};
export type TutorialRead = {
  uuid?: string;
  name: string;
  description?: string;
  is_active?: boolean;
  file: AssetRead;
  group_access?: string | null;
  ordering?: number | null;
  button_text?: string | null;
};
export type Category = {
  name: string;
  description?: string;
  cat_type?: string;
  parent?: string | null;
  ordering?: number;
};
export type CategoryRead = {
  uuid?: string;
  name: string;
  description?: string;
  cat_type?: string;
  parent?: string | null;
  ordering?: number;
};
export type Group = {
  name: string;
  parent?: string | null;
  permissions?: number[];
};
export type GroupRead = {
  id?: number;
  uuid?: string;
  name: string;
  parent?: string | null;
  permissions?: number[];
};
export const {
  useAccountDetailsTutorialListQuery,
  useAccountDetailsTutorialCreateMutation,
  useAccountDetailsTutorialReadQuery,
  useAccountDetailsTutorialUpdateMutation,
  useAccountDetailsTutorialDeleteMutation,
  useContentManagerCategoryListQuery,
  useUserGroupsListQuery
} = injectedRtkApi;
