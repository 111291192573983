import { useSelector } from 'react-redux';
import { getOpacityRequired } from '@ports/common/manualLoadingStateSlice';
import { Images } from '@business/assets/images';
import { RootState } from '@reduxjs/toolkit/query';
import { useEffect, useState } from 'react';

export const AppLoader = () => {
  const isOpacityRequired = useSelector(getOpacityRequired);
  const isPending = useSelector((state: RootState<any, any, any>) => state);
  const [isPendingData, setIsPendingData] = useState(false);
  const ignoreList = [
    'userUsersList',
    'regionZipcodesList',
    'regionZipcodesUploadFile',
    'regionStateUploadFile'
  ];
  useEffect(() => {
    const pendingData = Object.values(isPending)
      ?.filter((item: any) => item?.queries || item?.mutations)
      ?.flatMap((item: any) => [
        ...Object.values(item.queries || {}),
        ...Object.values(item.mutations || {})
      ])
      ?.filter((item: any) => !ignoreList.includes(item?.endpointName))
      ?.some((query: any) => query.status === 'pending');
    setIsPendingData(pendingData ? pendingData : false);
  }, [isPending]);
  return (
    <>
      {isPendingData && (
        <div
          data-testid="loader"
          className={`z-[99999] fixed top-0 left-0 w-screen h-screen flex align-center justify-center select-none overflow-none ${isOpacityRequired ? 'bg-white' : 'bg-white/75'} `}>
          <img
            src={Images.Loader}
            height={200}
            width={200}
            alt="loadingIcon"
            className="object-contain"
          />
        </div>
      )}
    </>
  );
};
