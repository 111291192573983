import { initializeApp } from 'firebase/app';
import { dotenv } from '@utils/constants/env';

const firebaseConfig = {
  apiKey: dotenv.APP_ID,
  authDomain: dotenv.AUTH_DOMAIN,
  projectId: dotenv.PROJECT_ID,
  storageBucket: dotenv.STORAGE_BUCKET,
  messagingSenderId: dotenv.MESSAGING_SENDER_ID,
  appId: dotenv.APP_ID,
  measurementId: dotenv.MEASUREMENT_ID
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
export default firebaseApp;
