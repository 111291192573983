import { Images } from '@business/assets/images';

export const AppMiniLoader = () => {
  return (
    <>
      <div className="top-0 left-0 flex align-center justify-center select-none overflow-none bg-white/75">
        <img
          src={Images.Loader}
          data-testid="mini-loader"
          height={80}
          width={80}
          alt="mini-loadingIcon"
          className="object-contain"
        />
      </div>
    </>
  );
};
