import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import { MatchingState } from '@business/interfaces/match';

const initialState: MatchingState = {
  id: ''
};

export const matchStateSlice = createSlice({
  name: 'matchStateSlice',
  initialState,
  reducers: {
    setMatchId: (state, action) => {
      state.id = action.payload.id;
    },
    resetMatchId: () => {
      return initialState;
    }
  }
});

export const { setMatchId, resetMatchId } = matchStateSlice.actions;
export const getMatchId = (state: RootState) => state.matchStateSlice.id;
