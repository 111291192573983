import { FC } from 'react';
import { Link } from 'react-router-dom';
import { ButtonProps } from '@business/interfaces/button';

export const getButtonClassName = (btnStyle: string, isDisabled: boolean) => {
  switch (btnStyle) {
    case 'primary':
      return `button button-primary`;
    case 'secondary':
      return `button button-secondary`;
    case 'error':
      return `button button-error`;
    case 'default-outline':
      return `button button-transparent`;
    case 'primary-outline':
      return `button button-transparent border-primary primary-color`;
    case 'secondary-outline':
      return `button button-transparent border-secondary secondary-color`;
    case 'no-outline':
      return `button button-no-outline`;
    case 'outline-filled':
      return `button button-outline-filled`;
    case 'new':
      return `button button-new`;
    default:
      return !isDisabled ? 'text-link' : '';
  }
};

const getSizeStyle = (size: string) => {
  switch (size) {
    case 'small':
      return 'text-sm py-1';
    case 'large':
      return 'text-lg py-3';
    default:
      return '';
  }
};

export const Button: FC<ButtonProps> = ({
  type = 'button',
  btnStyle = 'primary',
  className = '',
  onClick,
  isDisabled,
  size,
  fullWidth,
  href,
  label,
  startIcon,
  endIcon,
  loading,
  testid,
  children,
  classNameLabel,
  ...props
}) => {
  const sizeStyle = getSizeStyle(size);
  const finalClassName = `${getButtonClassName(
    btnStyle,
    isDisabled
  )} ${className} ${fullWidth ? 'w-full' : ''} ${sizeStyle} ${isDisabled ? 'opacity-50' : ''}`;
  return (
    <>
      {typeof href !== 'undefined' ? (
        <Link
          to={href}
          onClick={onClick}
          className={finalClassName}
          data-testid={testid ?? 'anchor-tag'}
          {...props}>
          {startIcon && !loading && (
            <span className="icon start-icon">{startIcon}</span>
          )}
          {loading && <div className="loader" data-testid="button-loader" />}
          {label}
          {children}
          {endIcon && !loading && (
            <span className="icon end-icon">{endIcon}</span>
          )}
        </Link>
      ) : (
        <button
          data-testid={testid ?? 'button-tag'}
          type={type}
          className={finalClassName}
          onClick={onClick}
          disabled={isDisabled}
          {...props}>
          {startIcon && !loading && (
            <span className="icon start-icon">{startIcon}</span>
          )}
          {loading && <div className="loader" data-testid="button-loader" />}
          {label && <span className={classNameLabel}>{label}</span>}

          {children}
          {endIcon && !loading && (
            <span className="icon end-icon">{endIcon}</span>
          )}
        </button>
      )}
    </>
  );
};
