import { useTranslation } from 'react-i18next';

export const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="copyright-row fixed text-[--na-secondary-color] bottom-2 text-center px-10 md:pb-0 md:text-left mt-2 text-xs w-full flex justify-between md:text-normal overflow-x-hidden">
      <div className="flex pl-3 md:pl-0">
        <span className="cursor-pointer">{t('termsOfUse')}</span> |{' '}
        <span className="cursor-pointer">{t('privacyPolicy')}</span> |{' '}
        {t('copyRight')} © 2024
      </div>
      <div className="z-50 md:text-right text-center !bottom-1 text-sm md:bg-transparent ">
        V 1.0.0.36
      </div>
    </footer>
  );
};
