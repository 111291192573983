import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import { LocalareaProps } from '@business/interfaces/localarea';

const initialState: LocalareaProps = {
  id: null,
  country: null
};

export const localareaDetailsSlice = createSlice({
  name: 'localareaDetails',
  initialState,
  reducers: {
    setAreaId: (state, action) => {
      state.id = action.payload.id;
    },
    setCountry: (state, action) => {
      state.country = action.payload.country;
    },
    resetAreaDetails: () => {
      return initialState;
    }
  }
});

export const { setAreaId, resetAreaDetails, setCountry } =
  localareaDetailsSlice.actions;
export const getAreaId = (state: RootState) => state.localareaDetails;
export const getCountry = (state: RootState) => state.localareaDetails;
