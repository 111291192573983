import { FC } from 'react';
import {
  Path
} from '@business/workflows/ComponentUI/BreadCrumbs/BreadCrumbs';

interface BaseAppLayoutProps {
  id?: string;
  mainClass?: string;
  breadcrumbPaths?: Path[];
  baseAppClass?: string;
  isSideBar?: boolean;
}

const BaseAppLayout: FC<React.PropsWithChildren<BaseAppLayoutProps>> = ({
  id = '',
  mainClass = '',
  children,
  breadcrumbPaths,
  baseAppClass,
  isSideBar = true
}) => {
  return (
    <>{children}</>
  );
};
export default BaseAppLayout;
