import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMenuItems,
  getResourceType,
  getTopMenuClicked,
  setMenuItems,
  setResource,
  setResourceType
} from '@ports/common/sideBarStateSlice';
import { useTranslation } from 'react-i18next';
import { routePaths } from '@utils/constants/routePaths';
import { useAccountDetailsTutorialListQuery } from '@ports/internal-services/user/userSlice';

export type Path = {
  label: string;
  href?: string;
};

export interface BreadcrumbProps {
  paths?: Path[];
}
type BreadCrumbType = {
  label: string;
  href?: string;
  no?: number;
};
const Breadcrumb: React.FC<BreadcrumbProps> = ({ paths }) => {
  const pathLength: number = paths?.length ?? 0;
  const breadCrumbPath = useSelector(getMenuItems);
  const topMenu = useSelector(getTopMenuClicked);
  const resourceType = useSelector(getResourceType);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [path, setPath] = React.useState<BreadCrumbType[]>([]);
  const { data: accountData, refetch } = useAccountDetailsTutorialListQuery({
    groupAccessName: resourceType
  });

  useEffect(() => {
    refetch();
  }, [resourceType]);
  useEffect(() => {
    if (breadCrumbPath?.length > 0) {
      const selected = breadCrumbPath.find(item => item.selected);
      setPath([
        ...(selected?.path ?? []),
        { label: t(selected?.label), href: selected?.href, no: selected?.no }
      ]);
    }
  }, [breadCrumbPath]);
  const routeChild = (selected: any[]) => {
    const selectedMenu = selected.findIndex(item => item.selected);
    if (selected?.[selectedMenu]?.child?.length > 0) {
      const childSelected = selected[selectedMenu].child.find(
        (item: any) => item.selected
      );
      if (childSelected && topMenu === 'view_resources') {
        const childItem = accountData?.results?.find(
          (childItem: any) => childItem?.name === childSelected?.label
        );
        dispatch(
          setResource({
            type: childItem.file?.content_type,
            url: childItem.file?.url,
            title: childItem.name,
            index: childSelected?.index,
            uuid: childItem.uuid,
            resource: resourceType
          })
        );
      }
    }
  };
  const handleClick = (selected: BreadCrumbType) => {
    if (selected?.href === routePaths.Resources) {
      dispatch(setResourceType('intro'));
    }
    const selectedMenu = breadCrumbPath.findIndex(
      item => item.no === selected.no
    );
    const tempArray = breadCrumbPath.map(item => ({
      ...item,
      child: item.child?.map((childItem: any) => ({ ...childItem }))
    }));
    if (selectedMenu === -1) {
      const itemIndex = tempArray.findIndex(
        (item: any) => item.href === selected.href
      );
      const updatedArray = tempArray.map((item: any, index: number) => ({
        ...item,
        selected: index === itemIndex,
        child: item.child?.map((childItem: any, childIndex: number) => ({
          ...childItem,
          selected: childIndex === 0
        }))
      }));
      routeChild(updatedArray);
      dispatch(setMenuItems(updatedArray));
    } else {
      tempArray[selectedMenu].child?.forEach((item: any, index: number) => {
        item.selected = index === 0;
      });
      routeChild(tempArray);
      dispatch(setMenuItems(tempArray));
    }
  };
  return (
    <div className="breadcrumbs pb-0" data-testid="bread-Crumb-tag">
      {path?.map((path, index) => (
        <span key={`breadcrumb-${index}`} data-testid="breadcrumb-item">
          {index > 0 && <span className="separator"> {'>'} </span>}
          {path.href ? (
            <Link
              to={path.href}
              className="hover-link text-md text-[var(--na-secondary-color)] text-xs font-bold cursor-pointer capitalize"
              onClick={() => handleClick(path)}>
              {path.label}
            </Link>
          ) : (
            <span
              className={`font-bold capitalize ${
                pathLength === 1 || (pathLength > 1 && index == 0)
                  ? 'text-[var(--na-lite-gray)]'
                  : 'text-black'
              }`}>
              {path.label}
            </span>
          )}
        </span>
      ))}
    </div>
  );
};

export default Breadcrumb;
