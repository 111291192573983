import { FC } from 'react';
import { Images } from '@business/assets/images';
import { SVGAssets } from '@business/assets/vectors';
import { Footer } from '@business/workflows/ComponentUI';

interface BaseAppLayoutProps {
  id?: string;
  mainClass?: string;
}

const AuthLayout: FC<React.PropsWithChildren<BaseAppLayoutProps>> = ({
  id = '',
  children
}) => {
  return (
    <div data-testid={id}>
      <div className="main-container">
        <main className="onboarding-screen-wrapper gl-position-right relative h-screen app-auth-layout" data-testid="app-auth-layout">
          <section className="signin-sec h-full">
            <div className="block md:flex w-full justify-between items-center h-auto md:h-screen">
              <div className="w-full md:w-6/12">
                <div
                  className="login-bg h-auto md:h-screen w-full flex items-start justify-center"
                  style={{
                    backgroundImage: `url(${Images.loginBackground})`
                  }}>
                  <SVGAssets.LasagnaLogo
                    className="w-[300px] mt-10 mb-10 md:mb-0"
                    data-testid="LasagnaLogo"
                  />
                </div>
              </div>
              <div className="w-full md:w-6/12 relative corner">{children}</div>
            </div>
          </section>
          
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default AuthLayout;
