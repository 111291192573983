import React from 'react';
import { useTranslation } from 'react-i18next';
import { SVGAssets } from '@business/assets/vectors';

const AppErrorPage: React.FC = (error: any) => {
  const { t } = useTranslation();
  return (
    <div className="app-error-page" data-testid="app-error-page">
      <div className="w-full flex justify-center items-center">
        <>
          <SVGAssets.PageError
            data-testid="page-error-image"
            className="page-error-image"
          />
        </>
      </div>
      <div className="w-full flex  justify-center items-center mt-4 pt-4">
        <p className="error-msg">
          {t('appError.applicationError')} {error?.error || ''}
        </p>
      </div>
      <div className="w-full flex  justify-center items-center mb-4 md:flex gap-5 mt-4 pt-4">
        <button
          data-testid="refresh-btn-base"
          type="button"
          className="button button-transparent border-primary primary-color rounded-full !px-8 py-1.5 mt-3 md:mt-0"
          onClick={() => window.location.reload()}>
          {t('appError.refreshPage', 'Refresh page')}
        </button>
      </div>
    </div>
  );
};

export default AppErrorPage;
