import React from 'react';
import { TabsProps } from '@business/interfaces/tabs';
import { Button } from '../Button';

export const Tabs: React.FC<TabsProps> = ({
  label,
  isActive,
  count,
  isUpperCase,
  activeColor = '',
  labelClassName,
  onClick,
  disabled,
  icon,
  value,
  isFullWidth
}) => {
  const tabClasses = isActive
    ? `tab-active ${activeColor}`
    : '';

  return (
    <Button
      className={`tab md:w-fit w-full ${labelClassName ?? ''} ${isUpperCase ? 'uppercase' : ''
        } ${tabClasses} ${isFullWidth ? 'w-1/2' : ''}`}
      onClick={onClick}
      btnStyle="link"
      data-testid={`tab-button-${value}`}
      disabled={disabled}>
      {icon && <span className="mr-2">{icon}</span>}
      {count ? `${label} (${count})` : label}
    </Button>
  );
};

export default Tabs;
