import { FC } from 'react';
import { AppLoader } from '@business/workflows/ComponentUI/AppLoader';
import { Header } from '@business/workflows/ComponentUI/Header';
import { Path } from '@business/workflows/ComponentUI/BreadCrumbs/BreadCrumbs';
import { Footer } from '@business/workflows/ComponentUI';

interface BaseAppLayoutProps {
  id?: string;
  mainClass?: string;
  breadcrumbPaths?: Path[];
  baseAppClass?: string;
}
const DashboardLayout: FC<React.PropsWithChildren<BaseAppLayoutProps>> = ({
  id = '',
  mainClass = '',
  children
}) => {
  return (
    <>
      <main className="lasagna-theme th-bg-image relative w-full app-dashboard-layout" data-testid="app-dashboard-layout">
        <div data-testid={id}>
          <div className={mainClass}>
            <div data-testid="header-component">
              <Header />
            </div>
            {children}
            <div data-testid="app-loader">
              <AppLoader />
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
export default DashboardLayout;
