import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { Language } from '@business/entities/localization/language';
import enUs from './en/common.json';
import esES from './es/common.json';

i18n.use(initReactI18next).init({
  compatibilityJSON: 'v3',
  lng: 'en-US',
  fallbackLng: 'en-US',
  resources: {
    'en-US': {
      translation: {
        ...enUs
      }
    },
    'es-ES': {
      translation: {
        ...esES
      }
    }
  },
  interpolation: {
    escapeValue: false // react already safes from xss
  }
});

const languages: Language[] = [
  {
    name: 'English',
    lng: 'en-US'
  },
  {
    name: 'Spanish',
    lng: 'es-ES'
  }
];

export default i18n;
export { languages };
