import { createSlice } from '@reduxjs/toolkit';
import { AlertType } from '@business/entities/alert';
import type { RootState } from './store';

const initialState: AlertType | null = null;

export const alertSlice = createSlice({
  name: 'alertStateSlice',
  initialState,
  reducers: {
    setAlertDetails: (state, action) => {
      return {
        alert: action.payload.alert,
        scope: action.payload.scope
      };
    },
    resetAlertState: () => {
      return initialState;
    }
  }
});

export const { setAlertDetails, resetAlertState } = alertSlice.actions;

export const getAlertState = (state: RootState) => state.alertStateSlice;
